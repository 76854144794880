import {
    auth,
    projectsCollection
  } from "@/firebase";
import CONSTANTS from '../../constants/miscellaneous.js';
import axios from "axios"
import {getFunctionUrl} from '@/functions/functionsConfig'
import store from "@/store/index.js";

const getDefaultProject=async()=>{
    // Missing WORKSPACE_ID
    let defaultProject= await projectsCollection
                                .where("workspace_id",'==',store.state.userWorkspace.id)
                                .where("userId","==",auth.currentUser.uid)
                                .where("default","==",true)
                                .get()
    
   
    defaultProject=defaultProject.docs[0];
    defaultProject={ id: defaultProject.id, ...defaultProject.data()}
    return defaultProject;
}

const getSharedProjects=async()=>{
    try{
        // BACKEND_API
        const requestUrl = getFunctionUrl('get_shared_projects');
        const requestBody = {
            "user_id": auth.currentUser.uid,
        }
        const sharedProjectsResponse = await axios.post(requestUrl,requestBody);
        console.log({sharedProjectsResponse})
        return {
            status:'success', data:sharedProjectsResponse.data.projects
        }

    }catch(error){  
        return {
            status:'error', error:error
        }

    }
}

const getRecentProjects=async(_limit=4)=>{
    try{
        // BACKEND_API
        const requestUrl = getFunctionUrl('get_recent_projects');
        const requestBody = {
            "user_id": auth.currentUser.uid,
        }
        const recentProjectsResponse = await axios.post(requestUrl,requestBody);
        // console.log({recentProjectsResponse})
        return {
            status:'success', data:recentProjectsResponse.data.recent_projects
        }

    }catch(error){  
        return {
            status:'error', error:error
        }

    }
}

const getRecentTeamProjects=async(workspaceId,_limit=4)=>{
    const projectsData= await projectsCollection
                                .where("workspace_id","==",store.state.userWorkspace.id)
                                .orderBy('updatedOn',"desc")
                                .limit(_limit)
                                .get();

    let projects=[]

    for(const project of await projectsData.docs){
        // console.log(project.id,'-->',project.data().name)
        projects.push({
            id:project.id,
            ...project.data()
        })
       
    }


    return projects;
}

const getAllWorkspaceProjects = async(workspaceId) =>{
    const projectsData = await projectsCollection.where("workspace_id", "==",store.state.userWorkspace.id).orderBy('name').get()
    let projects = []
    for(const project of await projectsData.docs){
        projects.push({
            id:project.id,
            ...project.data()
        })
        
    }
    return projects;

}

const getAllProjects=async(_limit=6,workspaceId=null)=>{
    console.log("inside getAllProjects",workspaceId)
    let projectsData;
    if(workspaceId){
    projectsData= await projectsCollection
                                .where("workspace_id","==",store.state.userWorkspace.id)
                                .where("default", "!=", true)
                                .orderBy("default") // This is needed cause we are using 'default' key as filter above
                                .orderBy("createdOn", "desc")
                                .limit(_limit)
                                .get();
    }
    else {
    projectsData= await projectsCollection
                                .where("workspace_id","==",store.state.userWorkspace.id)
                                .where("userId", "==", auth.currentUser.uid)
                                .where("default", "!=", true)
                                .orderBy("default") // This is needed cause we are using 'default' key as filter above
                                .orderBy("createdOn", "desc")
                                .limit(_limit)
                                .get();
    }


    let projects=[]
    let lastProjectDoc=null;
    let firstProjectDoc=null;

    for(const project of await projectsData.docs){
        console.log(project.id,'-->',project.data().name)
        projects.push({
            id:project.id,
            ...project.data()
        })
        lastProjectDoc=project;
    }

    firstProjectDoc=await projectsData.docs[0]

    return {projects,lastProjectDoc, firstProjectDoc };
}

const getAllProjectsWithoutLimit=async(workspaceId=null)=>{
    let projectsData;
    let projects=[]
    let lastProjectDoc=null;
    let firstProjectDoc=null;
    try{
        // if(workspaceId)
        projectsData = await projectsCollection
                                    .where("workspace_id","==",store.state.userWorkspace.id)
                                    .orderBy('createdOn',"desc")
                                    .get();
        // else
        // projectsData = await projectsCollection
        //                             .where("userId","==",auth.currentUser.uid)
        //                             .orderBy('createdOn',"desc")
        //                             .get();

    
        
        for(const project of await projectsData.docs){
            // console.log(project.id,'-->',project.data().name)
            projects.push({
                id:project.id,
                ...project.data()
            })
            lastProjectDoc=project;
        }
    
        firstProjectDoc=await projectsData.docs[0]
    
        return {projects,lastProjectDoc, firstProjectDoc };
    }catch(error){
        console.log('Error fetching all projects',error)
        return {projects,lastProjectDoc, firstProjectDoc };

    }

}

const getNextPaginatedProjects=async(lastDocSnapshot,workspaceId=null,_limit=6)=>{

    let projectsData;

    // if(workspaceId)
        projectsData= await projectsCollection
        .where("workspace_id","==",store.state.userWorkspace.id)
        .where("default", "!=", true)
        .orderBy("default") // This is needed cause we are using 'default' key as filter above
        .orderBy('createdOn',"desc")
        .startAfter(lastDocSnapshot)
        .limit(_limit)
        .get();
    // else
    //     projectsData= await projectsCollection
    //     .where("userId","==",auth.currentUser.uid)
    //     .where("default", "!=", true)
    //     .orderBy("default") // This is needed cause we are using 'default' key as filter above
    //     .orderBy('createdOn',"desc")
    //     .startAfter(lastDocSnapshot)
    //     .limit(_limit)
    //     .get();

    let projects=[]
    let lastProjectDoc=null;
    let firstProjectDoc=null;
    console.log('failed here',projectsData)
    for(const project of await projectsData.docs){
        // console.log(project.id,'-->',project.data().name)
        projects.push({
        id:project.id,
        ...project.data()
        })

        lastProjectDoc=project;
    }

    firstProjectDoc=await projectsData.docs[0]

    let isLastPage=false;
    if(projects.length===0) {
        isLastPage=true;
        return null;
    }

    return { projects,lastProjectDoc, firstProjectDoc };
}

const getPreviousPaginatedProjects=async(firstDocSnapshot,workspaceId=null,_limit=6)=>{
    // console.log(firstDocSnapshot.id)
    let projectsData;
    
    // if(workspaceId)
        projectsData= await projectsCollection
        .where("workspace_id","==",store.state.userWorkspace.id)
        .where("default", "!=", true)
        .orderBy("default") // This is needed cause we are using 'default' key as filter above
        .orderBy('createdOn',"desc")
        .limitToLast(_limit)
        .endBefore(firstDocSnapshot)
        .get();
    // else
    //     projectsData= await projectsCollection
    //     .where("userId","==",auth.currentUser.uid)
    //     .where("default", "!=", true)
    //     .orderBy("default") // This is needed cause we are using 'default' key as filter above
    //     .orderBy('createdOn',"desc")
    //     .limitToLast(_limit)
    //     .endBefore(firstDocSnapshot)
    //     .get();

    let projects=[]
    let lastProjectDoc=null;
    let firstProjectDoc=null;

    for(const project of await projectsData.docs){
        projects.push({
        id:project.id,
        ...project.data()
        })

        lastProjectDoc=project;
    }
    // console.log('for ',firstDocSnapshot.id,projects)
    firstProjectDoc=await projectsData.docs[0]

    let isFirstPage=false;
    if(projects.length===0) {
        isFirstPage=true;
        return null;
    }

    return { projects,lastProjectDoc, firstProjectDoc };
}

const getSingleProjectById=async(projectId)=>{
    const projectRef= await projectsCollection.doc(projectId)
    const projectData=await projectRef.get();
    console.log({projectData});
    if(projectData.exists){
        return {id:projectId,...projectData.data()};
    }else{
        console.log('Project does not exist');
        return null;
    }

                                
}


const createNewProject=async(projectName,workspaceId,createdBy,projectEmoji)=>{

    const response=await projectsCollection.add({
        default:false,
        name:projectName,
        userId:auth.currentUser.uid,
        createdOn:new Date(),
        updatedOn:new Date(),
        sessionCount:0,
        posterUrl:CONSTANTS.EmptyProjectThumbnail,
        workspace_id:store.state.userWorkspace.id,
        emoji:projectEmoji,
        createdBy: createdBy,
        currentViewer: null,
        activeUsers: []
    })

    const newProjectId=await response.id;
    const newProjectData=await projectsCollection.doc(newProjectId).get();
    const newProject = await newProjectData.data();

    return {...newProject, id:newProjectId};   
}

const assignSessionToProject=async(
    sessionId,
    moveFromProjectId,
    moveFromProjectName,
    moveToProjectId,
    moveToProjectName
)=>{
    const requestBody={
        "user_id": auth.currentUser.uid,
        "session_id": sessionId,
        "move_from_project_id": moveFromProjectId,
        "move_from_project_name": moveFromProjectName,
        "move_to_project_id": moveToProjectId,
        "move_to_project_name": moveToProjectName
    }
    // const requestUrl = "http://192.168.1.4:8081"
    // BACKEND_API
    const requestUrl=getFunctionUrl('move_session_projects')
    const response=await axios.post(requestUrl,requestBody)
    return response;
}

const deleteProject=async(projectId) =>{
    try{
        const projectRef= await projectsCollection.doc(projectId)
        const deletedProject= await projectRef.delete();
        console.log({deletedProject})
        return{ 
            status:'success',
        }
    }
    catch(error){
        console.log({error})
        return{ status:'error', error}
    }

}


const deleteProjectAndTags=async(projectId)=>{
    try{
        const requestBody={
            "user_id": auth.currentUser.uid,
            "project_id": projectId,
        }
        // BACKEND_API
        const requestUrl= getFunctionUrl('delete_project')
        const response=await axios.post(requestUrl,requestBody)
        return {status:'success',response};
    }catch(error){
        return { status:'failure',error};
    }
}

const getSessionCount=async(projectId)=>{
    try{
        const requestBody={
            "user_id": auth.currentUser.uid,
            "project_id": projectId,
        }
        // BACKEND_API
        const requestUrl= getFunctionUrl('get_session_count')
        // const requestUrl="http://127.0.0.1:8081"
        const response=await axios.post(requestUrl,requestBody)
        return {status:'success',response};
    }catch(error){
        return { status:'failure',error};
    }
}

const fetchSharedProjectInvites = async(projectId)=>{
    try{
        const requestBody={
            "project_id":projectId,
            "user_id":auth.currentUser.uid,
            "type":"READ"
        }
        // BACKEND_API
        const requestUrl = getFunctionUrl('teams_invite_collaborator');
        const invitationsResponse = await axios.post(requestUrl,requestBody)
        console.log({invitationsResponse})
        return { status:'success',data:invitationsResponse.data}

    }catch(error){
        return{ staus:'error',error}
    }
}

const sendSharedProjectInvite=async(inviteeEmail,projectId,access)=>{
    try{
        const requestBody={
            "invitee_email":inviteeEmail,
            "project_id":projectId,
            "sender_user_id":auth.currentUser.uid,
            "user_id":auth.currentUser.uid,
            "type":"CREATE",
            "access":access
        }
        // BACKEND_API
        const requestUrl = getFunctionUrl('teams_invite_collaborator');
        console.log('will send this request body: ',requestBody)
        const sendInviteResponse = await axios.post(requestUrl,requestBody)
        console.log({sendInviteResponse})
        return { status:'success',data:sendInviteResponse}

    }catch(error){
        return{ staus:'error',error}
    }
}

const acceptOrRejectSharedProjectInvite = async(invitationId,projectId,status)=>{
    try{
        const requestBody={
            "invitation_id":invitationId,
            "project_id":projectId,
            "status":status,
            "user_id":auth.currentUser.uid,
            "type":"UPDATE"
        }
        // BACKEND_API
        const requestUrl = getFunctionUrl('teams_invite_collaborator');
        const sendInviteResponse = await axios.post(requestUrl,requestBody)
        console.log({sendInviteResponse})
        return { status:'success',data:sendInviteResponse}

    }catch(error){
        return{ status:'error',error}
    }
}

const validateCollaborator = async(projectId)=>{
    try{
        const requestBody={
            "project_id":projectId,
            "user_id":auth.currentUser.uid,
            "type":"VALIDATE"
        }
        // BACKEND_API
        const requestUrl = getFunctionUrl('teams_invite_collaborator');
        const validateCollaboratorResponse = await axios.post(requestUrl,requestBody)
        console.log({validateCollaboratorResponse})
        return { status:'success',data:validateCollaboratorResponse.data}

    }catch(error){
        return{ staus:'error',error}
    }
}

const validateAutoTaggingAInotes = async(projectId)=>{
    try{
        const requestBody = {
            "user_id": auth.currentUser.uid,
            "project_id": projectId
        }
        // BACKEND_API
        // const requestUrl = getFunctionUrl('autotagging_project_handler') + '/validate-autotagging-project'
        // const requestUrl = 'http://127.0.0.1:8081/validate-autotagging-project'
        const requestUrl = 'https://us-central1-poc-project-309214.cloudfunctions.net/autotagging_project_handler_copy' + '/validate-autotagging-project'

        const response = await axios.post(requestUrl, requestBody)

        if (response.data.status == 'success'){
            return {status: 'success', data: response.data}
        } else {
            return {status: 'error', data: response.data}
        }

    } 
    catch(error){
        return {status:"error", error}
    }
}

const projectAccessControl=async(projectId,resourceType,resourceId)=>{
    try{
        // BACKEND_API
        const requestUrl = getFunctionUrl('project_access_control');
        const requestBody = {
            'user_id': auth.currentUser.uid,
            'resource_type':resourceType,
            'resource_id':resourceId,
            'project_id':projectId
        }
        const projectAccessControlResponse = await axios.post(requestUrl,requestBody);
        // console.log({recentProjectsResponse})
        return {
            status:'success', ...projectAccessControlResponse
        }

    }catch(error){  
        return {
            status:'error', error:error
        }

    }
}

export default { 
        getRecentProjects,
        getRecentTeamProjects,
        getDefaultProject,
        getSharedProjects,
        getAllProjectsWithoutLimit,
        getAllProjects, 
        getAllWorkspaceProjects,
        createNewProject,
        getNextPaginatedProjects,
        getPreviousPaginatedProjects,
        getSingleProjectById,
        assignSessionToProject,
        deleteProject,
        deleteProjectAndTags,
        getSessionCount,
        fetchSharedProjectInvites,
        sendSharedProjectInvite,
        acceptOrRejectSharedProjectInvite,
        validateCollaborator,
        validateAutoTaggingAInotes,
        projectAccessControl
}